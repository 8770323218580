<template>
    <div>
        <b-row>
            <b-col sm="2">
                <RadioGroup title="Reter ISS" :formName="formName" :disabled="disabled" field="group" :options="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                ]" v-model="tributacao.retido" />
            </b-col>
            <b-col sm="2" v-show="tributacao.retido">
                <SelectStatic title="Estado" :markFormDirty="true" :disabled="disabled" :initialValue="selectedUF"
                    :data="ufs" v-model="selectedUF" />
            </b-col>
            <b-col sm="8" v-show="tributacao.retido">
                <SelectStatic :markFormDirty="true" title="Município de Incidência do ISS" :disabled="disabled"
                    :initialValue="{ id: tributacao.codigoCidadeIncidencia, content: tributacao.descricaoCidadeIncidencia }"
                    :data="municipios" v-model="selectedMunicipio" />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="10">
                <SelectStatic title="Tipo de Tributação ISS" :markFormDirty="true" :formName="formName" :required="true"
                    :disabled="disabled" :initialValue="tributacao.tipoTributacao" :data="optionsTipoTributacao"
                    v-model="tributacao.tipoTributacao" />
            </b-col>
            <b-col sm="2">
                <InputNumber title="Alíquota ISS" field="aliquota" type="float" :formName="formName"
                    :disabled="disabled" :required="true" :maxLength="9" v-model="tributacao.aliquota" />
            </b-col>
        </b-row>
        <div>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Valor PIS" field="pis" :formName="formName" :disabled="disabled"
                        :required="false" :maxLength="9" v-model="tributacao.pis" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Valor COFINS" field="cofins" :formName="formName" :disabled="disabled"
                        :required="false" :maxLength="9" v-model="tributacao.cofins" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Valor CSLL" field="csll" :formName="formName" :disabled="disabled"
                        :required="false" :maxLength="9" v-model="tributacao.csll" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Valor INSS" field="inss" :formName="formName" :disabled="disabled"
                        :required="false" :maxLength="9" v-model="tributacao.inss" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Valor IR" field="ir" :formName="formName" :disabled="disabled"
                        :required="false" :maxLength="9" v-model="tributacao.ir" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <InputDecimal title="Deduções" field="deducoes" type="float" :formName="formName"
                        :disabled="disabled" :required="false" :maxLength="9" v-model="tributacao.deducoes" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";

import axios from 'axios';

import { mapGetters } from "vuex";

export default {
    name: "Tributacao",
    props: ["formName", "disabled", "nfse", "value"],
    components: {
        Molded,
        RadioGroup,
        SelectStatic,
        InputNumber,
        InputDecimal
    },
    data() {
        return {
            tributacao: {
                retido: false,
                aliquota: 0,
                codigoCidadeIncidencia: "",
                descricaoCidadeIncidencia: "",
                tipoTributacao: { id: "", content: "" },
                cofins: 0,
                csll: 0,
                inss: 0,
                ir: 0,
                pis: 0,
                deducoes: 0,
            },
            optionsTipoTributacao: [
                { content: 'Tributação no Município', id: '1' },
                { content: 'Tributação fora do Município', id: '2' },
                { content: 'Isenção', id: '3' },
                { content: 'Imune', id: '4' },
                { content: 'Exigibilidade Suspensa por Decisão Judicial', id: '5' },
                { content: 'Exigibilidade Suspensa por Procedimento Administrativo', id: '6' },
            ],
            ufs: [
                { id: 'AC', content: 'AC' },
                { id: 'AL', content: 'AL' },
                { id: 'AM', content: 'AM' },
                { id: 'AP', content: 'AP' },
                { id: 'BA', content: 'BA' },
                { id: 'CE', content: 'CE' },
                { id: 'DF', content: 'DF' },
                { id: 'ES', content: 'ES' },
                { id: 'GO', content: 'GO' },
                { id: 'MA', content: 'MA' },
                { id: 'MG', content: 'MG' },
                { id: 'MS', content: 'MS' },
                { id: 'MT', content: 'MT' },
                { id: 'PA', content: 'PA' },
                { id: 'PB', content: 'PB' },
                { id: 'PE', content: 'PE' },
                { id: 'PI', content: 'PI' },
                { id: 'PR', content: 'PR' },
                { id: 'RJ', content: 'RJ' },
                { id: 'RN', content: 'RN' },
                { id: 'RO', content: 'RO' },
                { id: 'RR', content: 'RR' },
                { id: 'RS', content: 'RS' },
                { id: 'SC', content: 'SC' },
                { id: 'SE', content: 'SE' },
                { id: 'SP', content: 'SP' },
                { id: 'TO', content: 'TO' }
            ],
            municipios: [],
            selectedUF: { id: "", content: "" },
            selectedMunicipio: { id: "", content: "" }
        };
    },
    computed: {
        ...mapGetters("validation", ["isFormValid"]),
        ...mapGetters("generic", ["event", "isLoading"]),
    },
    methods: {
        async getMunicipios() {
            if (!this.selectedUF) return;
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.selectedUF.id}/municipios`);
            this.municipios = response.data.map(municipio => ({
                id: municipio.id,
                content: municipio.nome
            }));
        }
    },
    watch: {
        nfse: {
            handler(nfseLocal) {
                this.tributacao.retido = nfseLocal.tributacao.retido;
                this.tributacao.codigoCidadeIncidencia = nfseLocal.tributacao.codigoCidadeIncidencia;
                this.tributacao.descricaoCidadeIncidencia = nfseLocal.tributacao.descricaoCidadeIncidencia;
                this.selectedMunicipio = { id: this.tributacao.codigoCidadeIncidencia, content: this.tributacao.descricaoCidadeIncidencia };

                if (nfseLocal.tributacao.tipoTributacao.id)
                    this.tributacao.tipoTributacao = this.optionsTipoTributacao.find(x => x.id == nfseLocal.tributacao.tipoTributacao.id);

                this.tributacao.aliquota = nfseLocal.tributacao.aliquota;
                this.tributacao.cofins = nfseLocal.tributacao.cofins;
                this.tributacao.pis = nfseLocal.tributacao.pis;
                this.tributacao.csll = nfseLocal.tributacao.csll;
                this.tributacao.inss = nfseLocal.tributacao.inss;
                this.tributacao.ir = nfseLocal.tributacao.ir;
                this.tributacao.deducoes = nfseLocal.tributacao.deducoes;

                this.$emit("input", this.tributacao);
            },
            deep: true,
        },
        event: {
            handler(event) {
                if (event.name == "taxation") {
                    this.tributacao.tipoTributacao = this.optionsTipoTributacao.find(x => x.id == event.data.tipoTributacao);
                    this.tributacao.aliquota = event.data.aliquota;
                }
            },
            deep: true,
        },
        tributacao: {
            handler() {
                this.$emit("input", this.tributacao);
            },
            deep: true,
        },
        selectedUF: {
            handler() {
                this.getMunicipios();
            },
            deep: true,
        },
        selectedMunicipio: {
            handler(municipio) {
                this.tributacao.codigoCidadeIncidencia = municipio.id.toString();
                this.tributacao.descricaoCidadeIncidencia = municipio.content;
            },
            deep: true,
        },
    }

};
</script>